import { FOCUS_VISIBLE_CLASSNAME, IS_HOVERABLE_CLASSNAME } from "@styles/constants";
import { CircleSize } from "@styles/theme";
import { Clickable } from "@ui/Clickable";
import { CssHelper } from "@utils/CssHelper";
import { withUnit } from "@uxf_base/utils/styling";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { IRootProps } from "./types";

export const Root = styled(Clickable)<IRootProps>(
    ({ innerComponentProps, theme }) => css`
        && {
            border-radius: ${withUnit(theme.borderRadius.full, "px")};
            display: block;
            flex: 0 0 auto;
            height: ${innerComponentProps?.size
                ? rem(theme.size.circle[innerComponentProps.size as CircleSize].radius)
                : null};
            width: ${innerComponentProps?.size
                ? rem(theme.size.circle[innerComponentProps.size as CircleSize].radius)
                : null};

            & > .circle {
                border: ${innerComponentProps?.variant === "white"
                    ? `${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border}`
                    : null};
                color: ${innerComponentProps?.light && innerComponentProps?.variant
                    ? theme.color.circle.light.text(innerComponentProps.variant)
                    : theme.color.circle.default.text(innerComponentProps?.variant ?? "text")};
                transition: ${CssHelper.transition("backgroundColor")};
            }

            &.${IS_HOVERABLE_CLASSNAME} {
                &:hover,
                &:active {
                    color: ${innerComponentProps?.light && innerComponentProps?.variant
                        ? theme.color.circle.light.text(innerComponentProps.variant)
                        : theme.color.circle.default.text(innerComponentProps?.variant ?? "text")};

                    & > .circle {
                        background-color: ${innerComponentProps?.light && innerComponentProps?.variant
                            ? theme.color.circle.light.background(innerComponentProps.variant)[1]
                            : innerComponentProps?.variant
                              ? theme.color.circle.default.background(innerComponentProps.variant)[1]
                              : null};
                    }
                }
            }

            &.${FOCUS_VISIBLE_CLASSNAME} {
                box-shadow: ${theme.shadow.buttonFocus};
                z-index: 1;
            }
        }
    `,
);
