import { FOCUS_VISIBLE_CLASSNAME } from "@styles/constants";
import { margins } from "@styles/mixins";
import { Input } from "@ui/Input/Input/Input";
import { CssHelper } from "@utils/CssHelper";
import { Responsive } from "@utils/Responsive";
import { getVisuallyHidden } from "@utils/styling";
import { spacing } from "@uxf/styles/units/spacing";
import { withUnit } from "@uxf_base/utils/styling";
import shade from "polished/lib/color/shade";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { ILabelProps, IRootProps, IStyledInputProps } from "./types";

export const Label = styled("label")<ILabelProps>(
    ({ visuallyHidden, theme }) => css`
        color: ${theme.color.palette.text};
        line-height: ${rem(theme.typography.variant.large.lineHeight)};
        letter-spacing: ${theme.typography.letterSpacing};
        font-weight: ${theme.typography.fontWeight.medium};
        margin-bottom: ${rem(theme.legacySpacing(0.5))};

        ${visuallyHidden ? getVisuallyHidden(visuallyHidden) : null};
    `,
);

export const Root = styled("div")<IRootProps>(
    ({ mb, ml, mr, mt, theme }) => css`
        flex: 0 0 auto;
        font-size: ${rem(theme.typography.variant.large.fontSize)};
        ${margins({ mb, ml, mr, mt }, theme)};

        & > ${Label} {
            &:hover,
            &:active {
                & + .input-component {
                    border-color: ${shade(0.1, theme.color.palette.inputBorder)};
                }
            }
        }

        &[aria-readonly="true"] {
            & > ${Label} {
                pointer-events: none;
            }
        }

        &[aria-disabled="true"] {
            & > ${Label} {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    `,
);

export const StyledInput = styled(Input)<IStyledInputProps>(
    ({ inputFontVariant, size, theme }) => css`
        && {
            background-color: ${theme.color.palette.white};
            border: ${withUnit(theme.border.default, "px")} solid ${theme.color.palette.inputBorder};
            border-radius: ${withUnit(theme.borderRadius.default, "px")};

            ${Responsive.facePaintedMin({
                fontSize: [
                    CssHelper.themeTextVariantFontSizeValue("large"),
                    CssHelper.themeTextVariantFontSizeValue("large"),
                    CssHelper.themeTextVariantFontSizeValue("large"),
                    inputFontVariant
                        ? rem(
                              theme.typography.variant[inputFontVariant as keyof typeof theme.typography.variant]
                                  .fontSize,
                          )
                        : CssHelper.themeTextVariantFontSizeValue("large"),
                ],
            })}

            height: ${size ? rem(theme.size.input[size]) : null};
            padding: 0
                ${size === "small" ? rem(spacing(1.5) - theme.border.default) : rem(spacing(2) - theme.border.default)};
            position: relative;
            transition: ${CssHelper.transition("borderColor")};

            & > .input {
                text-align: left;
            }

            &:hover,
            &:active {
                border-color: ${shade(0.1, theme.color.palette.inputBorder)};
            }

            /*&.${FOCUS_VISIBLE_CLASSNAME} {
                box-shadow: ${theme.shadow.buttonFocus};
                position: relative;
                z-index: ${theme.zIndex.focus};
            }*/

            &[aria-invalid="true"] {
                border-color: ${theme.color.palette.danger};

                &:hover,
                &:active {
                    border-color: ${shade(0.1, theme.color.palette.danger)};
                }
            }

            /*&[aria-invalid="false"] {
                border-color: ${theme.color.palette.primary};

                &:hover,
                &:active {
                    border-color: ${shade(0.1, theme.color.palette.primary)};
                }
            }*/
        }
    `,
);
