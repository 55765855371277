import { Box } from "@ui/Box";
import { PageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Layout } from "@ui/Layout/Layout";
import { IOgDataProps } from "@ui/Layout/types";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, ReactNode } from "react";

export interface RegistrationScreenProps {
    redirectMessageBox?: ReactNode;
    reverse?: boolean;
    children?: ReactNode;
    title: string;
    pageName?: string;
    logoDisabled?: boolean;
    hideNavBar?: boolean;
    canonicalUrl?: string;
    hideTools?: boolean;
    customHeaderContent?: ReactNode;
    ogData?: Partial<IOgDataProps>;
}

export const RegistrationScreen: FC<RegistrationScreenProps> = (props) => {
    return (
        <Layout
            backgroundColor="white"
            hideFooterVisually
            title={props.title}
            pageName={props.pageName}
            logoDisabled={props.logoDisabled}
            hideNavBar={props.hideNavBar}
            canonicalUrl={props.canonicalUrl}
            customHeaderContent={props.customHeaderContent}
            ogData={props.ogData}
        >
            <FlexBox
                alignItems={props.redirectMessageBox ? "stretch" : "center"}
                flexGrow="1"
                flexShrink="1"
                justifyContent="center"
            >
                <FlexBox flexDirection={["column-reverse", "column", null, props.reverse ? "row-reverse" : "row"]}>
                    {props.redirectMessageBox}
                    {props.redirectMessageBox ? (
                        <FlexBox alignItems="center" pl={[spacing(2), spacing(5)]} pr={[spacing(2), spacing(5)]}>
                            <Box maxWidth={480} ml="auto" mr="auto" pb={[48, null, 100]} pt={48}>
                                {props.children}
                            </Box>
                        </FlexBox>
                    ) : (
                        <PageContainer flexShrink="0" maxWidth={468} pb={[48, null, 100]} pt={48}>
                            {props.children}
                        </PageContainer>
                    )}
                </FlexBox>
            </FlexBox>
        </Layout>
    );
};
