import { FOCUS_VISIBLE_CLASSNAME } from "@styles/constants";
import { Box } from "@ui/Box";
import { TextSmall } from "@ui/Text";
import { composeRefs } from "@utils/composeRefs";
import clsx from "clsx";
import React, { forwardRef, ForwardRefRenderFunction, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { Label, Root, StyledInput } from "./styles";
import { IFieldInputProps } from "./types";

const FieldInputRenderFn: ForwardRefRenderFunction<any, IFieldInputProps> = (props, ref) => {
    const theme = useTheme();
    const innerRef = useRef<any>(null);

    const [isFocused, setIsFocused] = useState<boolean>();
    const { controlProps, CustomErrorComponent, formElementProps, hideErrorMessage, id, inputProps, hideLabel, label } =
        props;

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const node = innerRef.current;
        if (node) {
            node.focus();
        }
        if (inputProps?.onClick) {
            inputProps.onClick(e);
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const node = innerRef.current;
        if (node && node.classList.contains(FOCUS_VISIBLE_CLASSNAME)) {
            setIsFocused(true);
        }
        if (formElementProps?.onFocus) {
            formElementProps.onFocus(e);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        if (formElementProps?.onBlur) {
            formElementProps.onBlur(e);
        }
    };

    return (
        <Root
            aria-disabled={formElementProps?.disabled}
            aria-invalid={formElementProps?.invalid}
            aria-readonly={formElementProps?.readOnly}
            {...controlProps}
        >
            {label && (
                <Label htmlFor={id} visuallyHidden={!!hideLabel}>
                    {label}
                </Label>
            )}
            <StyledInput
                formElementProps={{
                    id,
                    ...(formElementProps ?? {}),
                    onBlur: handleBlur,
                    onFocus: handleFocus,
                }}
                ref={composeRefs(innerRef, ref)}
                size="default"
                {...inputProps}
                className={clsx("input-component", isFocused && FOCUS_VISIBLE_CLASSNAME, inputProps?.className)}
                onClick={handleClick}
            />
            {CustomErrorComponent ? (
                <CustomErrorComponent
                    aria-invalid={formElementProps?.invalid}
                    errorMessage={formElementProps?.errorMessage}
                    invalid={formElementProps?.invalid}
                    value={formElementProps?.value}
                />
            ) : !!formElementProps?.invalid && !!formElementProps?.errorMessage ? (
                hideErrorMessage ? (
                    <Box visuallyHidden>{formElementProps.errorMessage}</Box>
                ) : (
                    <TextSmall as="div" mt={theme.legacySpacing(0.5)} textColor="danger">
                        {formElementProps.errorMessage}
                    </TextSmall>
                )
            ) : null}
        </Root>
    );
};

export const FieldInput = forwardRef<any, IFieldInputProps>(FieldInputRenderFn);

FieldInput.displayName = "FieldInput";
