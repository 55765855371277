import { IconSizeEnum } from "@config/icons";
import { DISABLED_OPACITY } from "@styles/constants";
import { truncate } from "@styles/mixins";
import { buttonReset, inputAutofill } from "@uxf_base/styles/mixins";
import rem from "polished/lib/helpers/rem";
import { HTMLAttributes, InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

export const Root = styled("div")<HTMLAttributes<HTMLDivElement>>(
    ({ theme }) => css`
        align-items: center;
        color: ${theme.color.palette.textMutedLighter};
        display: flex;
        justify-content: flex-start;
        width: 100%;

        & > .start-icon {
            flex: 0 0 auto;
        }

        & > .end-icon {
            flex: 0 0 auto;
        }

        &[aria-readonly="true"] {
            pointer-events: none;
        }

        &[aria-disabled="true"] {
            opacity: ${DISABLED_OPACITY};
            pointer-events: none;
        }
    `,
);

export const StyledInput = styled("input")<InputHTMLAttributes<HTMLInputElement>>(
    ({ theme }) => css`
        ${buttonReset};
        color: ${theme.color.palette.text};
        display: block;
        flex: 1 1 auto;
        font-size: inherit;
        letter-spacing: inherit;
        line-height: 1;
        min-height: 1em;
        width: 100%;

        &:invalid {
            box-shadow: none;
        }

        &::-moz-placeholder {
            opacity: 1;
        }

        /* stylelint-disable property-no-vendor-prefix */
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* stylelint-enable */

        &[type="date"] {
            margin-right: ${rem(-(IconSizeEnum.medium + theme.legacySpacing(1.5)))};
            padding-right: ${rem(IconSizeEnum.medium + theme.legacySpacing(1.5))};
            position: relative;
        }

        /* stylelint-disable property-no-vendor-prefix */
        &[type="number"] {
            -moz-appearance: textfield;
        }
        /* stylelint-enable */

        &[type="date"]::-webkit-calendar-picker-indicator {
            background: transparent;
            color: transparent;
            height: ${rem(IconSizeEnum.medium)};
            margin: 0;
            padding: 0;
            position: absolute;
            right: 0;
            width: ${rem(IconSizeEnum.medium)};
        }

        &:not(:focus) {
            ${truncate}
        }

        &::placeholder {
            color: ${theme.color.palette.textMutedLighter};
            text-align: inherit;
        }

        &:focus {
            outline: none;
        }

        &:read-only,
        &[aria-readonly="true"] {
            color: ${theme.color.palette.textMuted};
        }

        ${inputAutofill(theme.color.palette.white, theme.color.palette.secondary)};
    `,
);
