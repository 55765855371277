import { IconSizeEnum } from "@config/icons";
import { Icon } from "@ui/Icon";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { useTheme } from "styled-components";
import { Root, StyledInput } from "./styles";
import { IInputProps } from "./types";

const preventScroll: EventListenerOrEventListenerObject = (e) => e.preventDefault();

const InputRenderFn: ForwardRefRenderFunction<any, IInputProps> = (props, ref) => {
    const theme = useTheme();
    const { endIcon, endIconProps, formElementProps, startIcon, startIconProps, ...restProps } = props;
    return (
        <Root
            aria-disabled={formElementProps?.disabled}
            aria-invalid={formElementProps?.invalid}
            aria-readonly={formElementProps?.readOnly}
            {...restProps}
        >
            {!!startIcon && (
                <Icon
                    className="start-icon"
                    mr={theme.legacySpacing(1.5)}
                    name={startIcon}
                    size={IconSizeEnum.medium}
                    color="textMuted"
                    {...startIconProps}
                />
            )}
            <StyledInput
                autoComplete={
                    !!formElementProps?.disabled || !!formElementProps?.readOnly
                        ? "off"
                        : formElementProps?.autoComplete ?? "off"
                }
                className="input"
                ref={ref}
                {...formElementProps}
                // Prevent number inputs from changing value by scroll wheel
                onFocus={
                    formElementProps?.type === "number"
                        ? (e) => e.target.addEventListener("wheel", preventScroll, { passive: false })
                        : undefined
                }
                onBlur={
                    formElementProps?.type === "number"
                        ? (e) => e.target.removeEventListener("wheel", preventScroll)
                        : undefined
                }
            />
            {!!endIcon && (
                <Icon
                    className="end-icon"
                    ml={theme.legacySpacing(1.5)}
                    name={endIcon}
                    size={IconSizeEnum.medium}
                    color="textMuted"
                    {...endIconProps}
                />
            )}
        </Root>
    );
};

export const Input = forwardRef<any, IInputProps>(InputRenderFn);

Input.displayName = "Input";
