import { Circle } from "@ui/Circle";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Root } from "./styles";
import { ICircleButtonProps } from "./types";

const CircleButtonRenderFn: ForwardRefRenderFunction<any, ICircleButtonProps> = (props, ref) => {
    const {
        badge,
        badgeProps,
        customIconSize,
        customIconSrcSize,
        iconColor,
        light,
        name,
        shadow,
        size,
        variant,
        title,
        ...restProps
    } = props;

    return (
        <Root
            innerComponent={Circle}
            innerComponentProps={{
                className: "circle",
                badge,
                badgeProps,
                iconColor,
                name,
                shadow,
                size,
                variant,
                light,
                title,
                customIconSize,
                customIconSrcSize,
            }}
            ref={ref}
            title={title}
            {...restProps}
        />
    );
};

export const CircleButton = forwardRef<any, ICircleButtonProps>(CircleButtonRenderFn);

CircleButton.defaultProps = {
    light: false,
    variant: "secondary",
};

CircleButton.displayName = "CircleButton";
