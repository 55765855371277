import { useClubPublicQuery, XClubPublicFragment } from "@gql-schema";
import { getClubId } from "@utils/redirUrlHelper";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";

export interface RedirectData {
    clubPublicData?: XClubPublicFragment;
    redirUrl: string;
}

export function useGetRedirectData(): RedirectData {
    const { query } = useRouter();

    const redirUrl = queryParamToString(query.redirUrl);
    const clubId = getClubId(redirUrl) || 0;

    const { data } = useClubPublicQuery({ variables: { clubId }, skip: !clubId });

    return {
        clubPublicData: data?.clubPublic,
        redirUrl,
    };
}
