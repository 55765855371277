import { mediaMin } from "@uxf_base/utils/styling";
import darken from "polished/lib/color/darken";
import transparentize from "polished/lib/color/transparentize";
import styled, { css } from "styled-components";
import { BackgroundImageRootProps } from "./types";

export const BackgroundImageRoot = styled("div")<BackgroundImageRootProps>(
    ({ theme, bgImage, variant }) => css`
        align-items: center;
        background:
            linear-gradient(
                ${darken(0.2, transparentize(0.45, theme.color.palette[variant ?? "primary"]))},
                ${darken(0.2, transparentize(0.45, theme.color.palette[variant ?? "primary"]))}
            ),
            ${bgImage ? "url(" + bgImage + ")" : null} center / cover;
        color: ${theme.color.palette.white};
        display: flex;
        justify-content: center;
        width: 100%;

        ${mediaMin(theme.breakpoint.lg)} {
            background-position: 30% 0;
        }
    `,
);
