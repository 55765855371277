import { routeToUrl } from "@app-routes";
import { ThemePaletteType } from "@app-types/css";
import { useTranslation } from "@translations/use-translation";
import { Button, IButtonProps } from "@ui/Button";
import { FlexBox } from "@ui/FlexBox";
import { Link } from "@ui/Link";
import { Text } from "@ui/Text";
import { getRedirUrlParts } from "@utils/redirUrlHelper";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, ReactNode, memo } from "react";
import { TypeSafeTranslate } from "../../../next-translate";
import { BackgroundImageRoot } from "./styles";

interface RedirectMessageBoxProps {
    bkgImage?: string;
    clubName: string;
    imageRootProps: {
        title: ReactNode;
        description?: ReactNode;
        buttons: {
            label: string;
            href: IButtonProps["href"];
        }[];
    };
    redirUrl: string;
    variant: ThemePaletteType;
}

function getMessageTitle(urlParts: Array<string>, t: TypeSafeTranslate["t"]) {
    if (urlParts) {
        switch (urlParts[2]) {
            case "hlasovani":
                return t("component-auth-redirect-message-box:function.switch.vote");
            case "prispevek":
                return t("component-auth-redirect-message-box:function.switch.posts");
            case "kontrola-insolvenci":
                return t("component-auth-redirect-message-box:function.switch.insolvency");
            case "zavady":
                return t("component-auth-redirect-message-box:function.switch.accident");
        }
        return urlParts[0] === "moje-domy"
            ? t("component-auth-redirect-message-box:function.if-else.house")
            : t("component-auth-redirect-message-box:function.if-else.group");
    }
    return t("component-auth-redirect-message-box:function.text");
}

export const RedirectMessageBoxComponent: FC<RedirectMessageBoxProps> = (props) => {
    const { t } = useTranslation();
    const { clubName, bkgImage, imageRootProps, redirUrl, variant } = props;

    const urlParts = getRedirUrlParts(redirUrl);

    if (!urlParts?.length && bkgImage) {
        return (
            <BackgroundImageRoot bgImage={bkgImage} variant={variant}>
                <FlexBox
                    as="section"
                    alignItems="center"
                    flexDirection="column"
                    childrenFlexProps={{ maxWidth: 512 }}
                    justifyContent="center"
                    gap={spacing(4)}
                    pb={[spacing(4), spacing(5)]}
                    pl={[spacing(2), spacing(5)]}
                    pr={[spacing(2), spacing(5)]}
                    pt={[spacing(4), spacing(5)]}
                >
                    <FlexBox gap={spacing(2)} alignItems="center" flexDirection="column">
                        <Text
                            as="h2"
                            textAlign="center"
                            textColor="white"
                            textFontWeight={["medium", null, null, "semiBold"]}
                            textVariant={["h3", null, null, "h2"]}
                        >
                            {imageRootProps.title}
                        </Text>

                        {imageRootProps.description && (
                            <Text textAlign="center" textColor="white" textFontWeight="medium" textVariant="large">
                                {imageRootProps.description}
                            </Text>
                        )}
                    </FlexBox>

                    {imageRootProps.buttons.map((button) => (
                        <Button key={button.label} kind="transparent" href={button.href} size="large" width={230}>
                            {button.label}
                        </Button>
                    ))}
                </FlexBox>
            </BackgroundImageRoot>
        );
    }

    if (!urlParts.length) {
        return null;
    }

    const messageTitle = getMessageTitle(urlParts, t);

    const messageSubtitle =
        urlParts[0] === "moje-domy"
            ? t("component-auth-redirect-message-box:message.subtitle.1", { houseName: clubName })
            : urlParts[0] === "komunita"
              ? t("component-auth-redirect-message-box:message.subtitle.2", { houseName: clubName })
              : null;

    return (
        <FlexBox
            as="section"
            alignItems="center"
            backgroundColor={variant}
            flexDirection="column"
            childrenFlexProps={{ maxWidth: 496 }}
            justifyContent="center"
            pb={[spacing(4), spacing(5)]}
            pl={[spacing(2), spacing(5)]}
            pr={[spacing(2), spacing(5)]}
            pt={[spacing(4), spacing(5)]}
        >
            <Text
                as="h2"
                mb={[spacing(2), null, null, spacing(4)]}
                textAlign="center"
                textColor="white"
                textFontWeight={["medium", null, null, "semiBold"]}
                textVariant={["h3", null, null, "h2"]}
            >
                {messageTitle} {t("component-auth-redirect-message-box:message-title")}{" "}
                <Link href={routeToUrl("auth-zone/login", { redirUrl })} underline underlineColor="bgLight">
                    {t("component-auth-redirect-message-box:links.1")}
                </Link>{" "}
                {t("component-auth-redirect-message-box:links.2")}{" "}
                <Link href={routeToUrl("auth-zone/registration", { redirUrl })} underline underlineColor="bgLight">
                    {t("component-auth-redirect-message-box:links.3")}
                </Link>
                .
            </Text>
            {messageSubtitle && (
                <Text textAlign="center" textColor="white" textVariant={["large", null, null, "h4"]}>
                    {messageSubtitle}
                </Text>
            )}
        </FlexBox>
    );
};

export const RedirectMessageBox = memo(RedirectMessageBoxComponent);

RedirectMessageBox.displayName = "RedirectMessageBox";
